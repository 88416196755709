import React from "react";
import styles from "./TransformCareer.module.css";

const TransformCareer = () => {
  return (
    <div className={styles.tc_container}>
      <div className={styles.tc_subcontainer}>
        <div className={styles.tc_heading}>
          Ready to transform your recruiting career?
        </div>

        <div className={styles.tc_points}>
          <div className={styles.tc_subpoints}>
            <div className={styles.tc_mark}></div>
            <div className={styles.tc_point_content}>
              Next cohort starts 
              April 12th, 2025
            </div>
          </div>

          <div className={styles.tc_subpoints}>
            <div className={styles.tc_mark}></div>
            <div className={styles.tc_point_content}>
              The cohort is limited per Batch.
            </div>
          </div>

          <div className={styles.tc_subpoints}>
            <div className={styles.tc_mark}></div>
            <div className={styles.tc_point_content}>
              Application deadline: 
              April 5th, 2025
            </div>
          </div>
        </div>

        <button
          className={styles.join_diploma}
          onClick={() => window.open("https://wa.me/+917416266999", "_blank")}
        >
          Join Diploma
        </button>
      </div>
    </div>
  );
};

export default TransformCareer;
