import React from "react";

const Gpt = () => {
  return (
    <div className="w-full py-20 px-2 md:px-40 items-center justify-center">
      <div className="flex flex-col items-center md:items-start">
        <h3>GPTs DeepResearch Report on this Program</h3>

        {/* Month 1 */}
        <p className="text-lg mt-6">
          GPT has ranked our program as the best Gen AI program for HR/TA
          professionals and has compared it across top global Gen AI programs
          for HR/TA. You can read the deep analysis report{" "}
          <a
            style={{ color: "#2093e5" }}
            href="https://chatgpt.com/share/67da7703-5d84-8011-b1c4-40fea5ad4e50"
            target="_blank"
          >
            right here.
          </a>
        </p>

        <a
          href="https://chatgpt.com/share/67da7703-5d84-8011-b1c4-40fea5ad4e50"
          target="_blank"
          className="mt-10 w-full flex items-center justify-center"
        >
          <img
            src="/genai/gpt.jpeg"
            alt="gpt result"
            style={{ maxWidth: "90%" }}
          />
        </a>
      </div>
    </div>
  );
};

export default Gpt;
