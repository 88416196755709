import React from "react";
import ReactPlayer from "react-player";

export const CourseVideos = () => {
  return (
    <div className="w-full py-20 px-2 md:px-40 items-center justify-center">
      <div className="flex flex-col items-center md:items-start">
        <h3>Solutions built by our Learners</h3>

        {/* Month 1 */}
        <p className="text-lg mt-6">
          Watch the below videos of how our learners are building some complex
          TA/HR Solutions by leveraging Prompting and Prompt Orchestration
          techniques.
        </p>

        <div className="flex gap-4 md:flex-nowrap px-4">
        <a
          href="https://www.linkedin.com/feed/update/urn:li:activity:7307331828007219200/"
          target="_blank"
          className="mt-10 w-full flex items-center justify-center"
        >
          <img
            src="/genai/video1.jpeg"
            alt="gpt result"
            className="w-full md:max-w-1/2"
          />
        </a>


        <a
          href="https://www.linkedin.com/feed/update/urn:li:activity:7297917533871906817/"
          target="_blank"
          className="mt-10 w-full flex items-center justify-center"
        >
          <img
            src="/genai/video2.jpeg"
            alt="gpt result"
            className="w-full md:max-w-1/2"
          />
        </a>
        </div>
      </div>
    </div>
  );
};
